import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("../app/lab-modules/home-module/home.module").then(
        (m) => m.HomeModule
      ),
  },

  { path: "", redirectTo: "/home", pathMatch: "full" },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
