// Angular
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
// Environment 
import { environment } from "../../../environments/environment";
// Interfaces
import { IApplicationIDE } from "../interfaces/ApplicationIDE";
import { ITestData } from "../interfaces/ITestData";
import { IInstallableApplicationIDE } from "../interfaces/InstallableApplicationIDE";
import { IMobileApp } from "../interfaces/MobileApp";
// Services
import { ReleaseService } from "./release.service";
import { SessionService } from "./session.service";
import { SessionSocketService } from "./session-socket.service";
import { ToasterService } from "./toaster.service";
import { UtilsService } from "./utils.services";
// Third Part
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, catchError, first } from "rxjs/operators";
import { Guid } from "guid-typescript";
@Injectable({
  providedIn: "root",
})

/**
 * This Service keeps track of all the applications avaialble for the current ide session
 * To install an applcation simply provide the application guid or application package guid
 * to InititateApplcationInstallOrToggleSelected method which will update application objects as required
 * If an application install fails the service defaults to null application and the user has so select some other application
 * manually
 *
 * HomeComponent works mostly on ApplicationPackageGUIDs
 */
export class ApplicationsService {
  applicationTestData;
  isInstallationFailed = false;

  envBase = environment.baseUrl;
  baseUrl = environment.baseUrl + "/api";
  parserURL = environment.parserURL;
  availableWebsites;

  lastTestRunID;
  currentWebsiteName;
  // application Id in lab

  applicationId$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  /**
   * List of all applications available from IDE
   */
  IdeApplications: BehaviorSubject<IInstallableApplicationIDE[]> =
    new BehaviorSubject<IInstallableApplicationIDE[]>([]);
  /**
   * Current Application Selected in IDE
   */
  SelectedApplication: BehaviorSubject<IInstallableApplicationIDE> =
    new BehaviorSubject<IInstallableApplicationIDE>(null);
  /**
   * This keeps track of avaialbility of List of applications
   */
  ApplicationsReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  /**
   * Disable All Actions while an application is being installed
   */
  DisableAllActions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  /**
   * This is needed the app is already installed but needs selection within IDE
   */
  select_ApplicationPackageGUID$: Subject<string> = new Subject<string>();
  /**
   * This is needed when there are already manu apps installed for the session
   */
  AlreadyInstalledApplicationPackageNames: Subject<string[]> = new Subject<
    string[]
  >();

  parentGUID$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  applicationTestDataList: Subject<ITestData[]> = new Subject<ITestData[]>();
  httpOptions = {};
  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private sessionService: SessionService,
    private utilsService: UtilsService,
    private toasterService: ToasterService
  ) {
    this.httpOptions = this.utilsService.httpOptions;
  }

  private setDownloadState(application: IInstallableApplicationIDE): void {
    application.downloadShow = true;
    application.loader = true;
  }

  InitiateApplicationInstallOrToggleSelected(Guid: string): void {
    const sessionSocketService = this.injector.get(SessionSocketService);
    const apps = this.IdeApplications.getValue();
    let _application = null as IInstallableApplicationIDE,
      _otherApplications = null as IInstallableApplicationIDE[];
    _application = apps.filter(
      (i) =>
        i.UserApplicationBuildList.filter((j) => j.AppPackageGUID === Guid)
          .length
    )[0];
    _otherApplications = apps.filter(
      (i) =>
        i.UserApplicationBuildList.filter((j) => j.AppPackageGUID !== Guid)
          .length
    );
    _otherApplications.forEach((i) => {
      i.selected = false;
      i.install = false;
    });

    if (_application?.downloadShow === true && _application?.loader === true) {
      _application.downloadShow = false;
      _application.loader = false;
      _application.selected = true;
      _application.install = true;
      this.isInstallationFailed = false;
      this.DisableAllActions$.next(false);
    } else if (_application.install) {
      _application.selected = true;
    } else {
      this.setDownloadState(_application);
      this.DisableAllActions$.next(true);
      sessionSocketService.SendApplicationInstall(Guid);
    }

    this.SelectedApplication.next(_application);
  }
  /**
   * Set loading to true of application if its not installed else set it to selected application
   * @param Guid Should be Application Guid or Application Package Guid
   * @param flag set to false if providing Application Guid or true for Application Package GUID
   */
  InitiateApplicationInstallOrToggleSelectedByApplicationId(
    _applicationId: number
  ): void {
    const apps = this.IdeApplications.getValue();
    const sessionSocketService = this.injector.get(SessionSocketService);
    let _application = null as IInstallableApplicationIDE,
      _otherApplications = null as IInstallableApplicationIDE[];
    _application = apps.filter((i) => i.ApplicationId == _applicationId)[0];
    _otherApplications = apps.filter((i) => i.ApplicationId != _applicationId);
    _otherApplications.forEach((i) => (i.selected = false));
    if (_application.install) {
      apps.forEach((i) => (i.selected = false));
      _application.selected = true;
      this.SelectedApplication.next(_application);
    } else {
      this.DisableAllActions$.next(true);
      sessionSocketService.SendApplicationInstall(
        _application.UserApplicationBuildList[0].AppPackageGUID
      );
      apps.forEach((i) => (i.selected = false));
      this.setDownloadState(_application);
      this.SelectedApplication.next(_application);
    }
  }

  InitiateWebsiteOnMobile(
    SessionGUID: string = "",
    selectedBrowser: string = ""
  ) {
    const sessionSocketService = this.injector.get(SessionSocketService);
    sessionSocketService.SendOpenBrowser(SessionGUID, selectedBrowser);
  }

  loadSelectedApplication(SessionGUID: string, DefaultApplicationGUID: string) {
    this.ApplicationsReady$.next(false);

    this.fetchPerticularApplication(DefaultApplicationGUID).subscribe(
      (data) => {
        this.IdeApplications.next([data]);
      },
      (err) => {
        console.log(err);
        this.toasterService.ShowToastMessage(err.error.userMessage, 0);
      },
      () => {
        this.ApplicationsReady$.next(true);
      }
    );

    this.AlreadyInstalledApplicationPackageNames.subscribe((list) =>
      this.SetApplicationsInstalled(list)
    );
  }

  fetchListOfAvailableWebsites(
    UserGUID: string,
    lastTestRunDate,
    filter: string
  ): Observable<any> {
    let query = `userGuid=${UserGUID}&RowsToreturn=20&Type=web`;
    if (lastTestRunDate) {
      query = query + `&ModifiedTime=${lastTestRunDate}`;
    }
    if (filter) {
      this.availableWebsites = [];
      query = query + `&Filter=${filter}`;
    }
    return this.httpClient.post<any[]>(
      this.baseUrl + `/Apps/ListsV2?${query}`,
      {},
      this.httpOptions
    );
  }

  isValidURL(url: string) {
    const data = JSON.stringify({
      URL: url,
    });
    return this.httpClient.post<any[]>(
      this.baseUrl + "/WebTests/ValidateURL",
      data,
      this.httpOptions
    );
  }

  createNewApplication(
    platform: string,
    appName: string,
    userGUID: string
  ): Observable<any> {
    const appGUID = Guid.create().toString();
    const data = JSON.stringify({
      AppGuid: appGUID,
      ApplicationID: "",
      Platform: platform,
      UserGuid: userGUID,
      AppName: appName,
      CreateDateTime: new Date(),
      LastRunDateTime: "",
    });
    return this.httpClient.post<any[]>(
      this.baseUrl + "/Apps/",
      data,
      this.httpOptions
    );
  }

  fetchPerticularApplication(
    applicationGUID: string
  ): Observable<IInstallableApplicationIDE> {
    return this.httpClient
      .get(
        `${this.envBase}/application-microservice/application/${applicationGUID}?isIDE=true`,
        this.httpOptions
      )
      .pipe(
        map((data: { data: IApplicationIDE; message: string }) => {
          let res = data.data;
          return !res ? null : this.utilsService.appTransformation(res);
        })
      );
  }

  /**
   * Fetch Data from API and map for personal use
   * @param UserGUID
   */
  fetchListOfApplicationsIDE(
    UserGUID: string,
    PlatformId: number = 1,
    filter: string
  ): Observable<IInstallableApplicationIDE[]> {
    let url = `${
      this.envBase
    }/application-microservice/applications/${this.parentGUID$.getValue()}?platformID=${PlatformId}`;
    if (filter) {
      url += `&filter=${filter}`;
    }

    return this.httpClient.get(url, this.httpOptions).pipe(
      map((data: { data: IApplicationIDE[]; message: string }) => {
        return data.data
          .filter((x) => x.UserApplicationBuildList.length)
          .map((app) => {
            return this.utilsService.appTransformation(app);
          });
      })
    );
  }
  /**
   * Set Application installed to true for list of applications by package name
   * @param ApplicationPackageNames String array containing Names of all applications installed
   */
  SetApplicationsInstalled(ApplicationPackageNames: string[]) {
    const apps = this.IdeApplications.getValue();
    apps.forEach((i) => {
      if (ApplicationPackageNames.includes(i.PackageName)) {
        i.install = true;
      }
    });
  }
  /**
   * Defaults the selected application to null and resets its UI parameters
   * @param Guid Should be Application Guid or Application Package Guid
   * @param flag set to false if providing Application Guid or true for Application Package GUID
   */
  ApplicationInstallFailed(Guid: string, flag = false): void {
    const apps = this.IdeApplications.getValue();
    let _application = null,
      _otherApplications = null;
    if (flag) {
      _application = apps.filter(
        (i) => i.UserApplicationBuildList[0].AppPackageGUID === Guid
      )[0];
      _otherApplications = apps.filter(
        (i) => i.UserApplicationBuildList[0].AppPackageGUID !== Guid
      );
    } else {
      _application = apps.filter((i) => i.ApplicationGUID === Guid)[0];
      _otherApplications = apps.filter((i) => i.ApplicationGUID !== Guid);
    }
    this.isInstallationFailed = true;
    _application.install = false;
    _application.loader = false;
    _application.downloadShow = false;
    _application.selected = false;
    _otherApplications.forEach((i) => (i.selected = false));
    // select first App
    this.SelectedApplication.next(apps[0]);
    this.DisableAllActions$.next(false);
  }
  iosVersionToString(minVersion: string) {
    if (minVersion) {
      let versionInNumber = 0;
      const arr = minVersion.split(".").map((x) => {
        return parseInt(x);
      });
      if (arr[0]) {
        versionInNumber += arr[0] * 1000;
      }
      if (arr[1]) {
        versionInNumber += arr[1] * 100;
      }
      if (arr[2]) {
        versionInNumber += arr[2] * 10;
      }
      if (arr[3]) {
        versionInNumber += arr[3] * 1;
      }
      return versionInNumber;
    } else {
      return null;
    }
  }
  
  /**
   * Parse Upload Mobile App Upload Data from API Response
   * @param body Response body of upload api
   */
  parseUploadFileDetailsFromResponse(body: any): IMobileApp {
    return {
      packageName: body.packageName || "",
      appFileLocation: body.fileLocation || "",
      packageMD5: body.hash || "",
      platform: body.platform || "",
      deviceTemplateId: 1, 
      deviceOSParameter: "",
      deviceTypeList: "",
      originalFileName: body.originalFileName || "",
      appIcon: body.appIcon || "",
      size: body.fileSize || "",
      currentVersion: body.version || "",
      minimum_sdk: `API Level ${body.minSdk || ''}`,
      minSdk: body.minSDK || null,
      targetSdk: body.targetSDK || null,
      version: body.version || "",
      applicationGuid: body.applicationGuid || "",
      appPackageGuid: body.appPackageGuid || "",
      platformId: body.platformId || 0,
      isNewApp: body.isNewApp 
    } as IMobileApp;
}

  /**
   * Add New Application from Parsed APK Data
   * @param userGUID user guid
   * @param _mobileAppMetaData mobile app meta data
   */
  AddApplication(userGUID, _mobileAppMetaData): Observable<any> {
    const toasterService = this.injector.get(ToasterService);
    const data = {
      packageName: _mobileAppMetaData.packageName,
      fileLocation: _mobileAppMetaData.fileLocation,
      platform: _mobileAppMetaData.platform,
      originalFileName: _mobileAppMetaData.originalFileName,
      appIcon: _mobileAppMetaData.appIcon,
      minSDK: _mobileAppMetaData.minSdk,
      targetSDK: _mobileAppMetaData.targetSdk,
      version: _mobileAppMetaData.version,
      liveUserID: _mobileAppMetaData.liveUserID,
      userID: _mobileAppMetaData.userID,
      hashValue: _mobileAppMetaData.hashValue,
      applicationGuid: _mobileAppMetaData.applicationGuid,
      appPackageGuid: _mobileAppMetaData.appPackageGuid,
      platformId: _mobileAppMetaData.platformId,
      isNewApp: _mobileAppMetaData.isNewApp,
    };
    return this.httpClient
      .post(
        this.envBase + `/application-microservice/applications/${userGUID}`,
        data,
        this.httpOptions
      )
      .pipe(
        first(),
        map((list: any) => {
          const applicationDetail = list.data;
          return applicationDetail;
        }),
        catchError((err) => {
          toasterService.ShowToastMessage("Application Upload Failed", 0, 3000);
          return new Observable((o) => o.error(err));
        })
      );
  }

  AddBuild(appGUID, _mobileAppMetaData): Observable<any> {
    const toasterService:any = this.injector.get(ToasterService);
    const data = {
      packageName: _mobileAppMetaData.packageName,
      fileLocation: _mobileAppMetaData.fileLocation,
      platform: _mobileAppMetaData.platform,
      originalFileName: _mobileAppMetaData.originalFileName,
      appIcon: _mobileAppMetaData.appIcon,
      minSDK: _mobileAppMetaData.minSdk,
      targetSDK: _mobileAppMetaData.targetSdk,
      version: _mobileAppMetaData.version,
      liveUserID: _mobileAppMetaData.liveUserID,
      userID: _mobileAppMetaData.userID,
      hashValue: _mobileAppMetaData.hashValue,
      applicationGuid: _mobileAppMetaData.applicationGuid,
      appPackageGuid: _mobileAppMetaData.appPackageGuid,
      platformId: _mobileAppMetaData.platformId,
      isNewApp: _mobileAppMetaData.isNewApp,
    };
    return this.httpClient
      .post(
        this.envBase +
          `/application-microservice/application-packages/${appGUID}`,
        data,
        this.httpOptions
      )
      .pipe(
        first(),
        map((list: any) => {
          const applicationDetail = list.data;
          return applicationDetail;
        }),
        catchError((err) => {
          toasterService.ShowToastMessage("Application Upload Failed", 0, 3000);
          return new Observable((o) => o.error(err));
        })
      );
  }

  updateDeviceAcquiredHubspot(sessionGUIDRecieved: string) {
    return this.httpClient.post<any[]>(
      this.baseUrl + "/BotManagement/UpdateDeviceAcquired",
      {
        Flag: true,
        SessionGUID: sessionGUIDRecieved,
      },
      this.httpOptions
    );
  }

  UpdateIDESessionApplicationTrack() {
    const rls = this.injector.get(ReleaseService);
    this.httpClient
      .put(
        environment.baseUrl +
          `/session-microservice/session/${
            this.sessionService.SessionGUID
          }/createAppTrack/${
            this.SelectedApplication.getValue().UserApplicationBuildList[
              this.SelectedApplication.getValue().selectedBuildIndex
            ].AppPackageGUID
          }`,
        {
          releaseId: rls.CurrentRelease.getValue().ReleaseModelId,
        },
        this.httpOptions
      )
      .subscribe();
  }
  UploadFile(appFile: File, UserGUID: string): Observable<HttpEvent<any>> {
    const url = `${this.parserURL}/${UserGUID}`;
    const formData = new FormData();
    formData.append("appFile", appFile);

    const req = new HttpRequest("POST", url, formData, {
      reportProgress: true,
    });

    return this.httpClient.request(req);
  }

  SetApplicationDownloadPercentage(percent: number) {
    const _apps = this.IdeApplications.getValue();
    if (_apps) {
      const app = _apps.filter(
        (i) => i.loader === true && i.downloadShow === true
      )[0];
      if (app) {
        app.downloadPercentage = percent;
      }
    }
  }

  getListOfTestDataByApplicationGUID(appGUID, isTypeAll: boolean = true) {
    let url = this.baseUrl + `/Applications/${appGUID}/GetListOfTestData`;
    if (isTypeAll == true) {
      url = url + `?Type=ALL`;
    }

    return this.httpClient.get<ITestData[]>(url, this.httpOptions).pipe(
      map((data) => {
        this.applicationTestData = data;
        this.applicationTestDataList.next(data);
        return data;
      })
    );
  }

  getListOfTestDataByApplicationID(applicationId: string) {
    let url =
      this.envBase + `/mobile-microservice/test-data/${applicationId}/app`;

    return this.httpClient.get<any>(url, this.httpOptions).pipe(
      map((data) => {
        data = data.data;
        this.applicationTestData = data;
        this.applicationTestDataList.next(data);
        return data;
      })
    );
  }

  getTestDataName(item) {
    if (
      ![
        "ADD_TEST_DATA",
        "SAVE_TEST_CASE_VARIABLE",
        "SET_TEST_CASE_VARIABLE",
      ].includes(item.actionType)
    ) {
      return `${item?.actionValue || "N/A"}`;
    } else {
      let testData = this.applicationTestData.find(
        (x) => x.TestDataID == item.actionValue
      );
      return testData ? `${testData.VariableName.trim()}` : "--";
    }
  }

  getTestData(item) {
    if (
      [
        "ADD_TEST_DATA",
        "SAVE_TEST_CASE_VARIABLE",
        "SET_TEST_CASE_VARIABLE",
      ].includes(item.action)
    ) {
      return item.actionSubValue || "N/A";
    } else if (!["ADD_TEST_DATA"].includes(item.action)) {
      if (item.action == "CLICK") {
        return item.actionValue == "--"
          ? item.selectedComponent.text != ""
            ? item.selectedComponent.text
            : "--"
          : item.actionValue;
      } else {
        return item.actionValue || "N/A";
      }
    } else {
      let testData = this.applicationTestData.find(
        (x) => x.VariableName?.toLowerCase() == item.actionValue
      );
      if (testData) {
        if (testData.TestDataType == "PATTERN") {
          return this.getParsedPattern(
            testData.TestDataValue,
            testData.ValueLength
          );
        } else {
          return testData.ValueLength || "";
        }
      } else if (item.selectedComponent.password) {
        return item.actionValue.replace(/./g, "*");
      } else {
        return "--";
      }
    }
  }

  getParsedPattern(testDataValue, valueLength) {
    switch (testDataValue) {
      case "{{ date(n, DD-MM-YYYY) }}":
        return testDataValue.replace("(n", `(${valueLength}`);
      case "{{ alphanumeric(n) }}":
        return testDataValue.replace("(n)", `(${valueLength})`);
      case "{{ alphabetic(n) }}":
        return testDataValue.replace("(n)", `(${valueLength})`);
      case "{{ numeric(n) }}":
        return testDataValue.replace("(n)", `(${valueLength})`);
      default:
        break;
    }
  }

  getSubscriptionsDetails(userGUID: string): Observable<any> {
    return this.httpClient.get<any>(
      this.envBase + "/subscription-microservice/user/subscription/" + userGUID,
      this.httpOptions
    );
  }
}
